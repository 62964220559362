import * as React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import {Container, Row, Col} from "react-bootstrap"
import {Icon} from "semantic-ui-react"
import ContactForm from "../components/ContactForm";
import { graphql, useStaticQuery } from "gatsby"

export default function Contact({location}) {
  
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            facebook
          }
        }
      }
    `
  )

  return (
    <Layout lang='hu' location={location}>
      <SEO lang='hu' title="Kapcsolat"/>
      <Container fluid className="h-100 d-flex flex-column primary-color-bg">
        <Row className="flex-grow-1">
          <Col className="align-self-center kontakt-form">
            <Container className="text-center">
              <h1 className="index-subtitle-text">Időpont előzetes egyeztetés alapján</h1>
              <p className="index-text"><Icon name="phone" className="secondary-color-text"/>+43676/3736655</p>
              <p className="index-text"><Icon name="mail" className="secondary-color-text"/>ordination@chirurgie-tihanyi.com</p>
              <p className="index-text"><a href="https://goo.gl/maps/AYVzdrnaBf4Y57kR7"><Icon name="home" className="secondary-color-text"/>Schulgasse 42, 7400 Oberwart</a></p>
              <p className="index-text"><a href={site.siteMetadata.facebook}><Icon name="facebook f" className="secondary-color-text"/>Ordination Dr. Zoltán Tihanyi</a></p>
              <ContactForm lang="hu"/>
            </Container>
          </Col>
          <Col className="align-self-center kontakt-map-wrapper">
            <Container className="text-center">
              <iframe className="kontakt-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2706.6287978263035!2d16.202977316217893!3d47.28251041830193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476e8d754ffc5c95%3A0x5cd92c9556115687!2sSchulgasse%2042%2C%207400%20Oberwart%2C%20Ausztria!5e0!3m2!1shu!2shu!4v1634812403249!5m2!1shu!2shu" allowFullScreen={false} loading="lazy"></iframe>
            </Container>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
